import * as React from 'react';
import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import backgroundImage0 from '../../assets/Automation.svg';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from "react-redux";

import DashboardViewService from '../../services/dashboardview.service';

export default function Widget1() {
  const groupName = useSelector((state) => state.splitbutton12GroupName.groupName);
  const dashboardName = useSelector((state) => state.splitbutton12DashboardName.dashboardName);
  const [paginationFlag, setPaginationFlag] = useState(false);
  const [dashboardConfigData, setDashboardConfigData] = useState(false);
  const [dashConfigFetchDone, setDashConfigFetchDone] = useState(false);

  //console.log(groupName, dashboardName)
  useEffect(() => {
    
    const interval = setInterval(() => {
      setPaginationFlag(prevFlag => !prevFlag);
    }, 5000);


    return () => clearInterval(interval);
  }, []);

  const viewportToPixels = (vw, vh) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    return {
      width: (vw / 100) * width,
      height: (vh / 100) * height
    };
  };

  const [size, setSize] = useState({
    width: viewportToPixels(100, 100).width,
    height: viewportToPixels(100, 100).height
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: viewportToPixels(100, 100).width,
        height: viewportToPixels(100, 100).height
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial size on mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = (e, direction, ref) => {
    setSize({
      width: ref.offsetWidth,
      height: ref.offsetHeight
    });
  };

const [tagFetchData, setTagFetchData] = useState(false);
const [tagData, setTagData] = useState();
const [fetchingProgress, setFetchingProgress] = useState(0);
const [tagDataFetchDone, setTagDataFetchDone] = useState(true);
const valuesArray = ['Solar_MFM_RN_Voltage', 'Solar_MFM_YN_Voltage', 'Solar_MFM_BN_Voltage', 'Solar_MFM_Avg_PN_Voltage', 'Solar_MFM_R_Current',
'Solar_MFM_Y_Current', 'Solar_MFM_B_Current', 'Solar_MFM_Total_Current', 'Solar_MFM_Avg_Current', 'Solar_MFM_RY_Voltage', 'Solar_MFM_YB_Voltage',
'Solar_MFM_BR_Voltage', 'Solar_MFM_Avg_PP_Voltage', 'Solar_MFM_Frequency', 'Solar_MFM_R_Power_Factor', 'Solar_MFM_Y_Power_Factor', 'Solar_MFM_B_Power_Factor',
'Solar_MFM_System_Power_Factor', 'Solar_MFM_R_Phase_KW', 'Solar_MFM_Y_Phase_KW', 'Solar_MFM_B_Phase_KW', 'Solar_MFM_Total_KW', 'Solar_MFM_3Phase_Avg_KW',
'Solar_MFM_R_Phase_KVA', 'Solar_MFM_Y_Phase_KVA', 'Solar_MFM_B_Phase_KVA', 'Solar_MFM_Total_KVA', 'Solar_MFM_R_Phase_KWh', 'Solar_MFM_Y_Phase_KWh', 'Solar_MFM_B_Phase_KWh',
'Solar_MFM_Total_KWh', 'Solar_MFM_R_Phase_KVAh', 'Solar_MFM_Y_Phase_KVAh', 'Solar_MFM_B_Phase_KVAh', 'Solar_MFM_Total_KVAh',]

//const valuesArray1 = ['tag1', 'tag2', 'tag3', 'tatg4']

// Progress bar
const ProgressBar = ({ progress }) => {
  return (
    <div className="progress">
      <div className="progress-bar" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  );
};

// Get selected dashboard configuration data from backend
useEffect(() =>{
  async function getDashboardConfigData()  {
  try {


    let dashboardType = 'DashboardConfig';
    let columnName = 'DashboardName';
    let columnValue = dashboardName;
    let response = await DashboardViewService.getColumnData(dashboardType, columnName, columnValue);
    
    if(response.data.length > 0){
  
      setDashboardConfigData(response.data);
      //console.log(response.data);
      setDashConfigFetchDone(true);

    }
 
  } catch (error) {
    console.log(error);

  }
}
getDashboardConfigData();


}, [dashboardName]);

// Get background image from server 
const [backgroundImage, setBackgroundImage] = useState(null);
useEffect(() => {
  async function handleBackgroundImageDownload()  {
    try {
      let imageName = dashboardName + "_BackgroundImage"
  
      const response = await DashboardViewService.downloadImageFromServer(imageName);
  
      //console.log(response.data);
      const base64 = btoa(new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte),
         ''));
      setBackgroundImage('data:image/jpeg/gif; base64,' + base64);
       // Set the appropriate MIME type based on your image format
       //const mimeType = response.headers['content-type']; // Get MIME type from response headers
       //setBackgroundImage(`data:${mimeType};base64,${base64}`); // Use appropriate MIME type
       //console.log(`data:${mimeType};base64,${base64}`)
       //console.log(backgroundImage0)

     
    } catch (error) {
      console.log(error);
      
    }

  }

  if(dashConfigFetchDone === true){
    handleBackgroundImageDownload();
    setDashConfigFetchDone(false);
  }
  
}, [dashConfigFetchDone]);


// Get Taglist data from backend
useEffect(() =>{
  async function getModbusTagListData()  {
  try {

    // get list of values of TagName field from tableViewData
    //const keyName = 'TagName'; // The key name you want to extract
    //const valuesArray = tableViewData.map((item) => item[keyName]);

    let dashboardName = "ModbusTagList";
    let columnsToRetrieve = ["TagName", "ProcessValue"];
    let columnToFilter ="TagName";
    let valueToMatch = valuesArray

    
    const response = await DashboardViewService.getMultiColumnsRows(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch );
    //console.log(response.data);
    if(response){
   
      //setTagData(response.data);
      setFetchingProgress(100);
      setTagDataFetchDone(true);

      let jsonData = response.data;
      // Create a mapping of TagName to ProcessValue
      const processValueMap = jsonData.reduce((acc, item) => {
        acc[item.TagName] = item.ProcessValue;
        return acc;
      }, {});

      // Map the valuesArray to include ProcessValue
      const updatedValues = valuesArray.reduce((acc, tag) => {
        acc[tag] = processValueMap[tag] || null; // Assign ProcessValue or null if not found
        return acc;
      }, {});

      setTagData(updatedValues);
      //console.log(updatedValues.tag1)

    }
 
  } catch (error) {
    console.log(error);

  }
}
if(tagFetchData === true){
  getModbusTagListData();
  setTagFetchData(false);
}


}, [tagFetchData]);

// update rowsdata perodically after every 1 second
useEffect(() => {
  const intervalId = setInterval(() => {
    if(tagDataFetchDone === true && tagFetchData === false){
      setTagDataFetchDone(false); // make it false to reset the previous rowsdatasetDone
      setTagFetchData(true); // make it true to start fetching tableView-ModbusTaglist-alarmconfig data
      //console.log("fetchdata");
      //console.log(tagDataFetchDone, tagFetchData)
    }
    setFetchingProgress((prevProgress) => Math.min(prevProgress + 10, 100));

    

  }, 2000);

  // Clear the interval when the component unmounts
  return () => clearInterval(intervalId);
}, [tagFetchData, tagDataFetchDone]);


  const FontSize = Math.max(8, (size.width / 100) * 1);

  // Adjusted aspect ratio and dimensions
  const imageAspectRatio = 1024 / 640; // System 2 Resolution
  const svgAspectRatio = size.width / size.height;

  let imageWidth = size.width;
  let imageHeight = size.height;

  if (svgAspectRatio > imageAspectRatio) {
    imageHeight = size.width / imageAspectRatio;
    imageWidth = size.width;
  } else {
    imageWidth = size.height * imageAspectRatio;
    imageHeight = size.height;
  }

  // Center image and position text relative to image size
  const imageX = (size.width - imageWidth) / 2;
  const imageY = (size.height - imageHeight) / 2;

  return (
    <React.Fragment>
      <Draggable>
        <Container
          maxWidth="xl"
          sx={{ mt: 2, mb: 1, ml: 0, mr: 0, p: 0 }} // Ensure no extra padding or margin
        >
          {fetchingProgress < 100 ? (
          <div style={{ height: 50, width: '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
          <p>Data fetching in progress...</p>
          <ProgressBar progress={fetchingProgress} />
        </div>
        ):(
          <Resizable
            size={{ width: size.width, height: size.height }}
            onResize={handleResize}
            enable={{
              top: true,
              right: true,
              bottom: true,
              left: true,
              topRight: true,
              bottomRight: true,
              bottomLeft: true,
              topLeft: true
            }}
          >
            <Paper
              style={{
                maxWidth: 'fit-content',
                flex: 1,
                padding: '0px', // Ensure no extra padding
                position: 'relative',
                overflow: 'hidden', // Ensure no overflow beyond the Paper component
                margin: 0 // Remove any default margin
              }}
              elevation={24}
            >

              <svg
                width={size.width}
                height={size.height}
                viewBox={`0 0 ${size.width} ${size.height}`}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid meet"
                style={{ display: 'block', margin: 0 }} // Ensure no extra space around SVG
              >
                <rect width="100%" height="100%" fill="#FFFFFF" />
                <image
                  href={backgroundImage}
                  x={imageX}
                  y={imageY}
                  width={imageWidth}
                  height={imageHeight}
                  preserveAspectRatio="xMidYMid meet"
                  style={{ imageRendering: 'optimizeQuality' }} // Improve image quality rendering
                />

                {/* Table1 */}
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 16.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_RN_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 19.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_YN_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 22.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_BN_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 25.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Avg_PN_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 29.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_R_Current}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 32.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Y_Current}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 34.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_B_Current}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 37.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
 
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Total_Current}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 40.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Avg_Current}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 43.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_RY_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 46.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_YB_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 49.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_BR_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 52.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Avg_PP_Voltage}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 54.9}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Frequency}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 57.9}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_R_Power_Factor}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 60.9}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Y_Power_Factor}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 63.8}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_B_Power_Factor}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 59.9}
                  y={imageY + (imageHeight / 100) * 66.8}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_System_Power_Factor}
                  </div>
                </foreignObject>

                {/* Table2 */}
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 16.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_R_Phase_KW}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 19.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Y_Phase_KW}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 23.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_B_Phase_KW}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 26.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Total_KW}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 29.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_3Phase_Avg_KW}
                  </div>
                </foreignObject>
        
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 32.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_R_Phase_KVA}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 34.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Y_Phase_KVA}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 37.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_B_Phase_KVA}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 40.0}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Total_KVA}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 43.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_R_Phase_KWh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 46.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Y_Phase_KWh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 49.5}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_B_Phase_KWh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 52.2}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Total_KWh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 54.9}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'red', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_R_Phase_KVAh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 57.8}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: '#F5A623', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Y_Phase_KVAh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 60.8}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'blue', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_B_Phase_KVAh}
                  </div>
                </foreignObject>
                <foreignObject
                  x={imageX + (imageWidth / 100) * 86.3}
                  y={imageY + (imageHeight / 100) * 63.8}
                  width={(size.width / 100) * 10}
                  height={(size.height / 100) * 10}
                  requiredExtensions="http://www.w3.org/1999/xhtml"
                >
                  <div xmlns="http://www.w3.org/1999/xhtml" style={{ fontSize: FontSize, fontWeight: 'bold', color: 'black', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {tagData.Solar_MFM_Total_KVAh}
                  </div>
                </foreignObject>

              </svg>
            </Paper>
          </Resizable>
        )}
        </Container>
      </Draggable>
    </React.Fragment>
  );
}
