import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons, GridEditInputCell, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Checkbox from "@mui/material/Checkbox";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


import ColorPicker from '../../components/ColorPicker.js';
import DashboardViewService from '../../services/dashboardview.service.js';
import DashboardConfigAdd from './DashboardConfigAdd.js';
import DashboardConfigEdit from './DashboardConfigEdit';
import { useDispatch, useSelector } from "react-redux";

const initialRows = [
  {
    id: 1,
    SrNo:1,
    ParameterName: 'Column1 Description',
    Value: 'Sr.No.',
  },
  {
    id: 2,
    SrNo:2,
    ParameterName: 'Column2 Description',
    Value: 'Device Name',
  },
  {
    id: 3,
    SrNo:3,
    ParameterName: 'Column3 Description',
    Value: 'Tag Name',
  },
  {
    id: 4,
    SrNo:4,
    ParameterName: 'Column4 Description',
    Value: 'Tag Description',
  },
  {
    id: 5,
    SrNo:5,
    ParameterName: 'Column5 Description',
    Value: 'Block Namae',
  },
  {
    id: 6,
    SrNo:6,
    ParameterName: 'Column6 Description',
    Value: 'Block ID',
  },
  {
    id: 7,
    SrNo:7,
    ParameterName: 'Column7 Description',
    Value: 'Process Value',
  },
  {
    id: 8,
    SrNo:8,
    ParameterName: 'Column8 Description',
    Value: 'Unit',
  },
  {
    id: 9,
    SrNo:9,
    ParameterName: 'Column9 Description',
    Value: 'LoLo SP',
  },
  {
    id: 10,
    SrNo:10,
    ParameterName: 'Column10 Description',
    Value: 'Low SP',
  },
  {
    id: 11,
    SrNo:11,
    ParameterName: 'Column11 Description',
    Value: 'High SP',
  },
  {
    id: 12,
    SrNo:12,
    ParameterName: 'Column12 Description',
    Value: 'HiHi SP',
  },
  {
    id: 13,
    SrNo:13,
    ParameterName: 'Column13 Description',
    Value: 'Alarm Status',
  },
  {
    id: 14,
    SrNo:14,
    ParameterName: 'LoLo Alarm Color',
    Value: 'Red',
  },
  {
    id: 15,
    SrNo:15,
    ParameterName: 'Low Alarm Color',
    Value: 'Tomato',
  },
  {
    id: 16,
    SrNo:16,
    ParameterName: 'High Alarm Color',
    Value: 'Tomato',
  },
  {
    id: 17,
    SrNo:17,
    ParameterName: 'HiHi Alarm Color',
    Value: 'Red',
  },

  {
    id: 18,
    SrNo:18,
    ParameterName: 'Page Size',
    Value: 10,
  },

  
];


// Add form open for widgetConfig.
function EditToolbar(props) {
 const { setAddRecordForm, setColorSelector, widgetName } = props;

  const handleClick = () => {
    //setAddRecordForm(true);
  };

  //startIcon={<AddIcon />} 
  //<Button onClick={() => setColorSelector(true)} style={{ background: '#4a90e2', color: 'white', border: 'none', width: '120px', height: '30px', fontSize:'11px'}}> Color Picker </Button>

  return (

      <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button color="primary" onClick={handleClick}>
          {widgetName} - Configuration
        </Button>
        
      
      </Grid>


    
  );
}

export default function WidgetConfig() {
  const { user } = useSelector((state) => state.auth);
  const widgetName = useSelector((state) => state.splitbutton12DashboardName.dashboardName);
  const [fetchData, setFetchData] = React.useState(true); // Fetch all row data enable bit
  
  const [rowsData, setRowsData] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const [rowData, setRowData] = React.useState([]);
  const [saveData, setSaveData] = React.useState(false);
  const [addRecordForm, setAddRecordForm] = React.useState(false)
  const [editRecordForm, setEditRecordForm] = React.useState(false)
  const [editRowData, setEditRowData] = React.useState(false)

  const [colorSelector, setColorSelector] = React.useState(false)

  const [snackbar, setSnackbar] = React.useState(null); // if delete Success then set to success otherwise will set to fail
  const handleCloseSnackbar = () => setSnackbar(null);

 
  // Get all rows data present inside the table for specific value of the given column name  (dashboardName, columnName, columnValue)
  //Get configuration of the selected widget
  useEffect(() =>{
    async function getColumnData()  {
    try {

      let dashboardName = "DashboardConfig";
      let columnName = "DashboardName";
      let columnValue = widgetName;
      //const offset = page * rowsPerPage;
      //console.log(page);
      //console.log(offset);
      //console.log(widgetName);
      
      const response = await DashboardViewService.getColumnData(dashboardName, columnName, columnValue );
      //console.log(response.data);
      const updatedArray = response.data.map((item, index) => ({
        ...item,
        SrNo: index + 1, // Start SrNo from 1
      }));

      setRowsData(updatedArray);
      setFetchData(false);
      
    } catch (error) {
      console.log(error);

    }
  }

  getColumnData();



  }, [widgetName, fetchData]);


  // Save  Specific row data 
  useEffect(() =>{
    async function saveAllRowsData()  {
    try {
      let dashboardName = "DashboardConfig"

      //console.log(rowsData);
      const response = await DashboardViewService.updateRowData(dashboardName, rowData);
      if(response){
        setSnackbar({ children: 'Updated Widget Configuration successfully with Data' , severity: 'success' });
      }
      //console.log(response.data);

      
    } catch (error) {
      setSnackbar({ children: 'Error occured while updating widget Configuration', severity: 'error' });
      console.log(error);

    }
  }
  if(saveData){
    saveAllRowsData();
    setSaveData(false)
  }


  }, [saveData]);


  const handleColorPaletteClick = () =>{

  

  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRecordEdited = (data) => {
    
    setEditRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    
  };

  const handleEditClick = (params) => () => {
    //setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    //handleColorPaletteClick();
    //console.log(params.row)
    setEditRowData(params);
    setEditRecordForm(true);

  };

  const handleRowDoubleClick = (params, event) => {
    //console.log(params);
    setEditRowData(params);
    setEditRecordForm(true);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    
  };

  const handleDeleteClick = (id) => () => {
    //setRowsData(rowsData.filter((row) => row._id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rowsData.find((row) => row._id === id);
    if (editedRow.isNew) {
      setRowsData(rowsData.filter((row) => row._id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRowsData(rowsData.map((row) => (row._id === newRow._id ? updatedRow : row)));
    setRowData({...newRow, email: user.email, role: user.role});
    setSaveData(true);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRecordAdded = (data) => {
    
    setAddRecordForm(false);
    setFetchData(true);  // reload the data again from backend
    //console.log("Record Added", data);
    
  };

  const handleButtonClick = (presentColor) => {
    //console.log(presentColor);
    
    setColorSelector(true);
        
  };

  const handleColorChanged = (data) => {
    
    setColorSelector(false);
    //console.log(data);
    
    
  };

  //const arrayWithoutSrNo = updatedArray.map(({ SrNo, ...item }) => item);

  const WidgetPropertiesSelect = ({ properties }) => {
    return (
      <FormControl fullWidth>
        
        <Select defaultValue="Visibility">
          {Object.entries(properties).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {`${key}: ${value}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

 

  const columns = [
 
    { field: 'SrNo', headerName: 'Sr No', width: 100, type: 'number', align: 'center', headerAlign: 'center'},
    { field: 'WidgetName', headerName: 'Parameter Name', width: 200, align: 'center', headerAlign: 'center'},
    { 
      field: 'WidgetDescription', 
      headerName: 'Widget Description', 
      width: 400,
      valueGetter: (params) => params.row.WidgetProperties.WidgetDescription, // Custom getter for WidgetDescription
    },
    //{ field: 'WidgetProperties', headerName: 'Properties', width: 400, align: 'center', headerAlign: 'center', hide: true,
      //renderCell: (params) => (
        //<WidgetPropertiesSelect properties={params.row.WidgetProperties} />
      //),
    //},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params)}
            color="inherit"
          />,
          
        ];
      },
    },
  ];

  return (
    <div>
    <Paper
      style={{ maxWidth: 'fit-content', flex: 1, padding: '6px' }}
      sx={{
        p: 1,
        width: '100%',
        margin: 'auto',
        position:"relative",
        
      }}
    >
     
     <Box sx={{ height: 600 }}>
    
      <DataGrid
        rows={rowsData}
        getRowId={(rowsData) => rowsData._id} // This line will set id of the datagrid row = _id of the mongodb database, otherwisw error can occured.
        columns={columns}
        hideFooterPagination = {true}
        editMode="row"
        isCellEditable={(params) => {
          if (params.row.SrNo <= 13 && params.colDef.field === "Enable") {
            return true; // Enable editing for the "Enable" column 
          }
          if (params.row.SrNo <= 13 && params.colDef.field === "Description") {
            return true; // Enable editing for the "Description" column 
          }
          if (params.row.SrNo <= 13 && params.colDef.field === "Width") {
            return true; // Enable editing for the "Width" column 
          }
          if (params.row.SrNo <= 13 && params.colDef.field === "TextAlignment") {
            return true; // Enable editing for the "TextAlignment" column 
          }
          if ((params.row.SrNo == 14 || params.row.SrNo == 15 || params.row.SrNo == 19 || params.row.SrNo == 20 || params.row.SrNo >= 24) && params.colDef.field === "Color") {
            return true; // Enable editing for the "Color" column
          }
          
          if ((params.row.SrNo == 17 || params.row.SrNo == 22) && params.colDef.field === "FontWeight") {
            return true; // Enable editing for the "FontWeight" column
          }
          if ((params.row.SrNo == 16 || params.row.SrNo == 21) && params.colDef.field === "FontSize") {
            return true; // Enable editing for the "FontSize" column
          }
          
          if ((params.row.SrNo == 18 || params.row.SrNo == 23) && params.colDef.field === "BorderStyle") {
            return true; // Enable editing for the "BorderStyle" column 
          }

          if (params.row.SrNo >= 29 && params.colDef.field === "Enable") {
            return true; // Enable editing for the "Enable" column 
          }
                    
        }}
        //rowModesModel={rowModesModel}
        //onRowModesModelChange={handleRowModesModelChange}
        //onRowEditStop={handleRowEditStop}
        //processRowUpdate={processRowUpdate}

        onRowDoubleClick={handleRowDoubleClick} // Double click event handler
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setAddRecordForm, setColorSelector, widgetName, },
        }}
      />
      </Box>

    {addRecordForm && (
    <DashboardConfigAdd open= {addRecordForm} close = {handleRecordAdded} />
    )}

    {editRecordForm && (
      <DashboardConfigEdit params = {editRowData} open= {editRecordForm} close = {handleRecordEdited} />
    )}


    {colorSelector && (
        <ColorPicker open= {colorSelector} close = {handleColorChanged} />
    )}

    {!!snackbar && (
          <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
    )}

      
    
    </Paper>

    </div>
    
  );
}