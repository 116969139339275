import { createSlice } from '@reduxjs/toolkit';

const splitButton12= createSlice({
  name: 'groupName',
  initialState: { groupName: '' },
  reducers: {
    setGroupName: (state, action) => {
      state.groupName = action.payload;
    },
  },
});

export const { setGroupName} = splitButton12.actions;
//export default splitButton12.reducer;
export const splitbutton12GroupReducer = splitButton12.reducer;


const splitButton12WidgetName= createSlice({
  name: 'dashboardName',
  initialState: { dashboardName: '' },
  reducers: {
    setDashboardName: (state, action) => {
      state.dashboardName = action.payload;
    },
  },
});

export const { setDashboardName} = splitButton12WidgetName.actions;
//export default splitButton1.reducer;
export const splitButton12WidgetNameReducer = splitButton12WidgetName.reducer;

const splitButton12Description= createSlice({
  name: 'groupDescription',
  initialState: { groupDescription: '' },
  reducers: {
    setGroupDescription: (state, action) => {
      state.groupDescription = action.payload;
    },
  },
});

export const { setGroupDescription} = splitButton12Description.actions;
//export default splitButton12Description.reducer;
export const splitButton12DescriptionReducer = splitButton12Description.reducer;