import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import DashboardViewService from '../services/dashboardview.service.js';
import { useDispatch, useSelector } from "react-redux";
import { setGroupName, setDashboardName} from "../slices/splitbutton12.js";

export default function SplitButton12({ projectName, dashboardType, selectedDashboardGroup, selectedDashboard }) {
  const [open, setOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexDashboard, setSelectedIndexDashboard] = useState(0);
  const [groupList, setGroupList] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);
  const [fetchDashboardList, setFetchDashboardList] = useState(false);
  const [menuitemIndex, setMenuItemIndex] = useState(0);
  const [dashboardGroup, setDashboardGroup] = useState();
  const [dashboard, setDashboard] = useState();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userGroupNameList = user.groupnamelist;

  useEffect(() => {
    async function fetchGroupNames() {
      if (dashboardType === "DashboardView") {
        const response1 = await DashboardViewService.getAllRowsData("DashboardGroupList");
        if (response1) {
          const dashboardGroupNameList = [...new Set(response1.data.filter(obj => obj.GroupName).map(obj => obj.GroupName))];
          setGroupList(dashboardGroupNameList.sort());
          setFetchDashboardList(true);
        }
      }
    }
    fetchGroupNames();
  }, [dashboardType]);

  useEffect(() => {
    async function fetchDashboardNames() {
      if (dashboardType === "DashboardView") {
        const response2 = await DashboardViewService.getAllRowsData("DashboardList");
        if (response2) {

          const dashboardlistofSelectedGroup = response2.data.filter(obj =>
            obj.dashboardgroupnamelist.includes(groupList[0])
          );

          const filteredObjects2 = dashboardlistofSelectedGroup.filter(obj =>
            obj.groupnamelist.some(value => userGroupNameList.includes(value))
          );
          let dasbrdList = filteredObjects2.map(item => item.DashboardName).sort();
          selectedDashboard(dasbrdList[0])
          dispatch(setGroupName(groupList[0]))
          dispatch(setDashboardName(dasbrdList[0]))
          setFetchDashboardList(false);
        }
      }
    }
    
    if (fetchDashboardList === true) {
      fetchDashboardNames();
    }
  }, [fetchDashboardList]);

  useEffect(() => {
    async function fetchDashboardNames() {
      if (dashboardType === "DashboardView") {
        const response2 = await DashboardViewService.getAllRowsData("DashboardList");
        if (response2) {
          const dashboardlistofSelectedGroup = response2.data.filter(obj =>
            obj.dashboardgroupnamelist.includes(dashboardGroup)
          );

          const filteredObjects2 = dashboardlistofSelectedGroup.filter(obj =>
            obj.groupnamelist.some(value => userGroupNameList.includes(value))
          );
          setDashboardList(filteredObjects2.map(item => item.DashboardName).sort());
        }
      }
    }
    
    if (open && subMenuOpen) {
      fetchDashboardNames();
    }
  }, [subMenuOpen, open]);

  const handleClick = () => {
    setDashboard(dashboardList[selectedIndexDashboard]);
    setSubMenuOpen(false);
  };

  const handleSubMenuItemClick = (index) => {
    setSelectedIndex(menuitemIndex);
    setSelectedIndexDashboard(index)
    
    selectedDashboard(dashboardList[index]);
    selectedDashboardGroup(dashboardGroup);

    dispatch(setGroupName(dashboardGroup));
    dispatch(setDashboardName(dashboardList[index]));
    handleClick();
  };

  const handleSubMenuOpen = () => {
    setSubMenuOpen(true);
  };

  const handleMenuItemClick = (index) => {
    setMenuItemIndex(index)
    setDashboardGroup(groupList[index]);
     
    handleSubMenuOpen();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setSubMenuOpen(false);
  };

  return (
    <React.Fragment>
     <ButtonGroup variant="text" size="small" ref={anchorRef}>
        <Button
          size="small"
          color="success"
          onMouseEnter={handleToggle} // Trigger on hover
          onMouseLeave={handleClose}  // Optional: close on mouse leave
          style={{ textTransform: 'none' }}
        >
          {groupList[selectedIndex] || "Select Group"}
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 10, // this should be higher than other componenet otherwise options can get disabled and user will not be able to select widget name
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center top' }}
          >
            <Paper
            sx={{
              minHeight: '100px', // Minimum height
              minWidth: '100px',  // Minimum width
              padding: 2,         // Optional padding
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {groupList.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                      style={{ textTransform: 'none' }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/* Submenu Popper */}
      <Popper
          sx={{
              zIndex: 11, // should be higher than other components
          }}
          open={subMenuOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal={false} // Set to true if you want it to stay in context
          placement="right-start" // Adjust this as needed
      >
          {({ TransitionProps }) => (
              <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: 'left bottom' }}
              >
                  <Paper
                   sx={{
                    minHeight: '150px', // Minimum height
                    minWidth: '150px',  // Minimum width
                    padding: 2,         // Optional padding
                    }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem>
                              {dashboardList.map((item, index) => (
                                  <MenuItem
                                      key={item}
                                      selected={index === selectedIndexDashboard}
                                      onClick={() => handleSubMenuItemClick(index)}
                                      style={{ textTransform: 'none' }}
                                  >
                                      {item}
                                  </MenuItem>
                              ))}
                          </MenuList>
                      </ClickAwayListener>
                  </Paper>
              </Grow>
          )}
      </Popper>

    </React.Fragment>
  );
}
