import axios from "axios";
import {API} from './api.js';
const API_URL = API + '/configuration/'
//const API_URL = "http://localhost:3000/api/configuration/";
//const API_URL = "http://192.168.3.108:3000/api/configuration/";

const getDashboardList = (projectname, dashboardtype) => {
  //console.log(projectname, dashboardtype)
  return axios.post(API_URL + "alldashboard", {
      projectname,
      dashboardtype,
    })
};

//Get count of records present in table
const getRowsCount = (dashboardName) => {

  return axios.post(API_URL + "count", {
    dashboardName,
    })
    
};


// get specific rows data, init record = page * rowsPerPage and number of record to fetch = rowsPerPage
const getRowsData = (dashboardName, offset, rowsPerPage) => {

  return axios.post(API_URL + "data", {
      dashboardName,
      offset,
      rowsPerPage
    })
};

// Get all rows data present inside the table
const getAllRowsData = (dashboardName) => {
  return axios.post(API_URL + "all", {
    dashboardName,
  });
 
};

// Get all rows data present inside the table for specific value of the given column name
const getColumnData = (dashboardName, columnName, columnValue) => {
  return axios.post(API_URL + "columnData", {
    dashboardName,
    columnName,
    columnValue,
  });
 
};

// Get all field names of the specific table/collection
const getSchemaKeys = (dashboardName) => {
  return axios.post(API_URL + "schemaKeys", {
    dashboardName,

  });
 
};


// Save specific row data present inside the table
const updateRowData = (dashboardName, rowData, auditTrailData) => {

  return axios.post(API_URL + "update", {
    dashboardName,
    rowData,
    auditTrailData,
  });
   
};


// Create or Add document/record to existing collection/table
const createRow= (dashboardName, rowData) => {
    return axios.post(API_URL + "create", {
      dashboardName,
      rowData,
      })
};



// Delete specific document/record from existing collection/table
const deleteRow = (dashboardName, rowId) => {
    return axios.post(API_URL + "delete", {
      dashboardName,
      rowId,
      })
  };
  

// Delete many tags from Tableview, trendview or from reortview, if same tag get deleted from madbus taglist.
const deleteManyTag = (dashboardName, tagName) => {
  return axios.post(API_URL + "deletemanytag", {
    dashboardName,
    tagName,
    })
};

const getIPDetails = () => {
  return axios.post(API_URL + "getipdetails", {

    })
};

const changeIPDetails = (connectionName, address, netmask, gateway, dns ) => {
  return axios.post(API_URL + "changeipdetails", {
    connectionName,
    address,
    netmask,
    gateway,
    dns 
    })
};

const getSysTime = () => {
  return axios.post(API_URL + "getsystime", {

    })
};

const changeSysTime = (hours, minutes, seconds) => {
  return axios.post(API_URL + "changesystime", {
    hours,
    minutes,
    seconds,
    })
};

const changeSysDate = (day, month, year) => {
  return axios.post(API_URL + "changesysdate", {
    day,
    month,
    year,
    })
};

const ConfigurationViewService = {
  getDashboardList,
  getRowsCount,
  getRowsData,
  getAllRowsData,
  getColumnData,
  getSchemaKeys,
  updateRowData,
  createRow,
  deleteRow,
  deleteManyTag,
  getIPDetails,
  changeIPDetails,
  getSysTime,
  changeSysTime,
  changeSysDate,
}

export default ConfigurationViewService;