import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";
import DashboardViewService from '../services/dashboardview.service.js';


const rememberme = JSON.parse(localStorage.getItem("rememberMe"));
let user = "";

if(rememberme){
  //console.log(rememberme);
  if(rememberme.rememberMe === true){
    //console.log(rememberme.rememberMe);
    user = JSON.parse(localStorage.getItem("user"))
    
  }else{
    user = JSON.parse(sessionStorage.getItem("user"));
  }
  
}
//console.log(rememberme);
//console.log(user);




export const register = createAsyncThunk(
  "auth/register",
  async ({ username, lastname, email, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(username, lastname, email, password);
      //console.log(response);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      console.log(error);
      //console.log(error.message);
      //console.log(error.toString());
      //console.log(error.response );
      //console.log(error.response.data);
      console.log(error.response.data.message);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password, rememberMe }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password, rememberMe);
      return { user: data }; //Thunk will store recived user info/data to the storage of browser
    } catch (error) {
      //console.log(error.response.data.message);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();

    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  //console.log("logout event dispatched")
  await AuthService.logout();
});

//Download profile photo
export const downloadProfilePhoto = createAsyncThunk(
  "auth/downloadProfilePhoto",
  async ({email}, thunkAPI) => {
    try {

      const response = await AuthService.downloadProfilePhoto(email);
      
      //const blob = await response.data.blob();
      console.log(response);
      //const file = URL.createObjectURL(response);
      
      return (response.data);
      //return blob;
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uploadProfilePhoto = createAsyncThunk(
  "auth/uploadProfilePhoto",
  async ({email, singleImage }, thunkAPI) => {
    try {
      const response = await AuthService.uploadProfilePhoto(email, singleImage);
      
      //thunkAPI.dispatch(setMessage("Profile Photo Change Successful"));
      return response.data;
      // if returning only return response then serialisation error can occured
      //hence return as response.data
      
    } catch (error) {
      console.log(error);
      //console.log(error.message);
      //console.log(error.toString());
      //console.log(error.response );
      //console.log(error.response.data);
      //console.log(error.response.data.message);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const uploadImageToServer = createAsyncThunk(
  "auth/uploadImageToServer",
  async ({imageName, singleImage }, thunkAPI) => {
    try {
      const response = await DashboardViewService.uploadImageToServer(imageName, singleImage);
      
      //thunkAPI.dispatch(setMessage("Profile Photo Change Successful"));
      return response.data;
      // if returning only return response then serialisation error can occured
      //hence return as response.data
      
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//Download image from server
export const downloadImageFromServer = createAsyncThunk(
  "auth/downloadImageFromServer",
  async ({imageName}, thunkAPI) => {
    try {

      const response = await DashboardViewService.downloadImageFromServer(imageName);
      
      //const blob = await response.data.blob();
      //console.log(response);
      //const file = URL.createObjectURL(response);
      
      return (response.data);
      //return blob;
    } catch (error) {
      console.log(error);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changepassword = createAsyncThunk(
  "auth/changepassword",
  async ({email, password, newpassword, remark }, thunkAPI) => {
    try {

      const response = await AuthService.changepassword(email, password, newpassword, remark);
      
      thunkAPI.dispatch(setMessage("Password Change Successful"));
      //console.log(response);
      return response.data;
      // if returning only return response then serialisation error can occured
      //hence return as response.data
      
    } catch (error) {
      console.log(error);
      //console.log(error.message);
      //console.log(error.toString());
      //console.log(error.response );
      //console.log(error.response.data);
      //console.log(error.response.data.message);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changeuserinfo = createAsyncThunk(
  "auth/changeuserinfo",
  async ({userid, username, lastname, email, password, remark }, thunkAPI) => {
    try {
      const response = await AuthService.changeuserinfo(userid, username, lastname, email, password, remark);
      
      thunkAPI.dispatch(setMessage("User Upgradation Successful"));
      //console.log(response);
      return response;
      
    } catch (error) {
      console.log(error);
      //console.log(error.message);
      //console.log(error.toString());
      //console.log(error.response );
      //console.log(error.response.data);
      //console.log(error.response.data.message);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//Send Selected project to the Server and get resonse with Project Description
export const selectedProject = createAsyncThunk(
  "auth/selectedProject",
  async ({email, selectedproject}, thunkAPI) => {
    try {
      const response = await AuthService.selectedProject(email, selectedproject);
      
      thunkAPI.dispatch(setMessage("Project Selection Successful"));
      //console.log(response);
      return response;
      
    } catch (error) {
      console.log(error);
      //console.log(error.message);
      //console.log(error.toString());
      //console.log(error.response );
      //console.log(error.response.data);
      //console.log(error.response.data.message);
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };



const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.fulfilled, (state, { payload }) => {
        state.isLoggedIn = false;
        //state.resp = payload
    }) 
      .addCase(register.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
    })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoggedIn =true;
        state.user = payload.user;
    })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoggedIn = false;
        state.user = null;
    })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.isLoggedIn = false;
        state.user = null;
    })
      .addCase(changepassword.fulfilled, (state, { payload }) => {
      state.isLoggedIn = false;
    })
          
  }, 
});
export default authSlice.reducer;
