import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';

import { TextField, Button, FormControlLabel, FormControl, makeStyles } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from "react-redux";

import {uploadImageToServer} from "../../slices/auth";
import { clearMessage } from "../../slices/message";

import DashboardViewService from '../../services/dashboardview.service.js';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function SaveChangesDialog(props) {
  const [message, setMessage] = useState();

  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);

  const [open, setOpen] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);

  const [auditTrailData, setAuditTrailData] = React.useState([]);  // AuditTrail Data

  const handleClickOpen = () => {

    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);

  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  
  
  const handleClickSaveChanges = () => { 

    setSaveData(true);
  };

  

  //  update existing table info or GrouptList
  useEffect(() =>{
    async function saveRowData()  {
    try {

      let dashboardName = "DashboardConfig"
      let rowData = {...props.rowData};

      // Blank value check for the  one of the form field
      if (rowData.Description === '' || rowData.Width=== '' ) {
        setOpenFaildialog(true);
        setMessage("one or more fields are blank, please correct it.");
        setSaveData(false);
        setOpen(false); // Close "Save changes" window

      } else {
        //console.log(rowData);
        //let response = false;
        const response = await DashboardViewService.updateRowData(dashboardName, rowData, auditTrailData)
        .then(() => {

            if(props.singleImage){
              let imageName = props.imageName;
              let singleImage = props.singleImage;
              dispatch(uploadImageToServer({ imageName, singleImage }))
              .unwrap()
              .then(() => {
                setOpenSuccessDialog(true);
                setMessage("Record Edited Successfully.");
                setSaveData(false);
                setOpen(false); // Close "Save changes" window
              })
            
            }


        })
      }
        
        

    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Editing Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);

  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Dialog to ask surity to edit user's data, with or withoout password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>
        <DialogContent dividers>
          <DialogContentText>
            Are you sure to edit table details ?
          </DialogContentText>
          
        </DialogContent>

        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

export default function DashboardConfigEdit ({params, open, close}) {

  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'DashboardView';
  const widgetName = useSelector((state) => state.splitbutton12DashboardName.dashboardName);

  const [snackbar, setSnackbar] = React.useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const initialUserData = {
    ProjectName:params.row.ProjectName,
    DashboardType:params.row.DashboardType,
    DashboardName:params.row.DashboardName,
    WidgetName: params.row.WidgetName,
    WidgetProperties:params.row.WidgetProperties,

    _id: params.row._id,

      
  };
  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");

  const [tableListData, setTableListData] = React.useState(initialUserData);
  //console.log(params.row)
 
  const handleTextFieldChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
  };


  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }

  

  const widgetListForDescription = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const WidgetDescriptionEnabled= widgetListForDescription.includes(tableListData.WidgetName);

  const widgetListForVisiblity = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const VisibilityEnabled= widgetListForVisiblity.includes(tableListData.WidgetName);

  const widgetListForImage = ['BackgroundImage', 'Image'];
  const ImageEnabled= widgetListForImage.includes(tableListData.WidgetName);

  const widgetListForBackground = [ 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const BackgroundEnabled= widgetListForBackground.includes(tableListData.WidgetName);

  const widgetListForFontWeight = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const FontWeightEnabled= widgetListForFontWeight.includes(tableListData.WidgetName);

  const widgetListForValueType = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const ValueTypeEnabled= widgetListForValueType.includes(tableListData.WidgetName);

  const widgetListForTooltip = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const TooltipEnabled= widgetListForTooltip.includes(tableListData.WidgetName);

  const widgetListForTooltipText = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const TooltipTextEnabled= widgetListForTooltipText.includes(tableListData.WidgetName);

  const widgetListForRedirection = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const RedirectionEnabled= widgetListForRedirection.includes(tableListData.WidgetName);

  const widgetListForRedirectionLink = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const RedirectionLinkEnabled= widgetListForRedirectionLink.includes(tableListData.WidgetName);

  const widgetListForHeight = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const HeightEnabled= widgetListForHeight.includes(tableListData.WidgetName);

  const widgetListForWidth = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const WidthEnabled= widgetListForWidth.includes(tableListData.WidgetName);

  const widgetListForXpos = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const XposEnabled= widgetListForXpos.includes(tableListData.WidgetName);

  const widgetListForYpos = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const YposEnabled= widgetListForYpos.includes(tableListData.WidgetName);

  const widgetListForML = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const MLEnabled= widgetListForML.includes(tableListData.WidgetName);

  const widgetListForMR = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const MREnabled= widgetListForMR.includes(tableListData.WidgetName);

  const widgetListForMT = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const MTEnabled= widgetListForMT.includes(tableListData.WidgetName);

  const widgetListForMB = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const MBEnabled= widgetListForMB.includes(tableListData.WidgetName);

  const widgetListForPadding = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const PaddingEnabled= widgetListForPadding.includes(tableListData.WidgetName);

  const widgetListForFontSize = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const FontSizeEnabled= widgetListForFontSize.includes(tableListData.WidgetName);

  const widgetListForBorder = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const BorderEnabled= widgetListForBorder.includes(tableListData.WidgetName);

  const widgetListForBlockSize = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const BlockSizeEnabled= widgetListForBlockSize.includes(tableListData.WidgetName);

  const widgetListForZindex = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const ZindexEnabled= widgetListForZindex.includes(tableListData.WidgetName);

  const widgetListForBgColor = ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const BgColorEnabled= widgetListForBgColor.includes(tableListData.WidgetName);

  const widgetListForLabelColor = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const LabelColorEnabled= widgetListForLabelColor.includes(tableListData.WidgetName);

  const widgetListForOverflowWrap = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const OverflowWrapEnabled= widgetListForOverflowWrap.includes(tableListData.WidgetName);
  
  const widgetListForTextAlign = ['Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'];
  const TextAlignEnabled= widgetListForTextAlign.includes(tableListData.WidgetName);
  

  const handleValueChange = (key) => (event) => {
    const newValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    // Update the original data directly
    //console.log(key, newValue);
    setTableListData((prevData) => ({
        ...prevData,
        WidgetProperties: {
            ...prevData.WidgetProperties,
            [key]: newValue,
        },
    }));
  };
  //console.log(tableListData.WidgetProperties.Tooltip);

  const fileInput = React.useRef();
  const [image, setImage] = useState(null);
  const [targetImage, setTargetImage] = useState(null);

  const handleClickIconButton = (event) => {
    fileInput.current.click()
    
  };

  // set image data which need to upload to backend
  const handleImageUpload = (event) => {
    //console.log(event.target.files[0]);
    
    if(event.target.files.length !== 0){

      const sizeInMB = event.target.files[0].size / (1024 * 1024); // Convert size to MB
      const maxSizeInMB = 2; // for example, 5 MB
      if (sizeInMB >= maxSizeInMB) {
        console.log(`File size exceeds ${maxSizeInMB} MB.`);
        setSnackbar({ children: `File size exceeds ${maxSizeInMB} MB.`, severity: 'error' });
      } else {
        setTargetImage(event.target.files[0]); 
        //console.log(URL.createObjectURL(event.target.files[0]))
        setImage( URL.createObjectURL(event.target.files[0]));
      }
      
    }
    
    //Revoke the image after logout or clsoing the windows to make browser memory free
    //URL.revokeObjectURL(image);

  };

  // get stored image from backend
  const handleImageDownload= async () => {
    try {

      //let imageName = widgetName + "_BackgroundImage"
      let imageName = tableListData.WidgetProperties.WidgetDescription

      const response = await DashboardViewService.downloadImageFromServer(imageName);

      //console.log(response.data);
      const base64 = btoa(new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte),
         ''));
      setImage('data:image/jpeg; base64,' + base64);
     
      //setImage(response.data);
    } catch (error) {
      console.log(error);
      
    }
  }

  useEffect(() => {handleImageDownload();}, []);

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  

  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            minWidth: '650px', // Change this value as needed
            maxWidth: '650px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Edit properties of the component - {tableListData.WidgetName}
        </BootstrapDialogTitle>
        
        {/* Widget properties list */}
        <DialogContent dividers>
          {Object.entries(tableListData.WidgetProperties).map(([key, value]) => {
            const renderComponent = () => {
              switch (key) {
                case 'WidgetDescription':
                  return (
                    <div>{ WidgetDescriptionEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <TextField
                        value={value}
                        disabled // Make this field disabled and non-editable
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div>

                    
                  );
                case 'Visibility':
                  return (
                    <div>
                      {VisibilityEnabled &&(
                        <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                        <Typography variant="body1">{key}</Typography>
                        <Select
                          value={value}
                          onChange={handleValueChange(key)}
                          fullWidth
                          margin="dense"
                        >
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </Select>
                        </Box>

                      )}
                    </div>
                    
                  );
                case 'ImageName':
                    return (
                      <div>{ ImageEnabled &&(
                        <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                          <Typography variant="body1"> Image </Typography> 
                          <Tooltip title="Upload Image">
                          <IconButton
                          onClick={handleClickIconButton}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <input 
                            ref={fileInput} 
                            type="file" 
                            style={{ display: 'none' }} 
                            onChange={handleImageUpload}
                            accept="image/*"
                            id="image-upload"
                          />
                          
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                              <SmallAvatar>
                                <ModeEditOutlineIcon />
                              </SmallAvatar>
                            }
                          >
                            {/* Use Box to create a rectangular shape */}
                            <Box
                              component="img"
                              alt="Upload Image"
                              src={image}
                              sx={{
                                width: 500, // Set your desired width
                                height: 200, // Set your desired height
                                borderRadius: 0, // Optional: use this for slightly rounded corners
                              }}
                            />
                          </Badge>
                        </IconButton>

                          
                          </Tooltip>
                        </Box>
                        )}
                      </div>
                    ); 
                case 'Background':
                  return (
                    <div>{ BackgroundEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <Select
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      >
                        <MenuItem value="nontransparent">Non-transparent</MenuItem>
                        <MenuItem value="transparent">Transparent</MenuItem>
                      </Select>
                      </Box>

                      )}
                    </div>
                    
                  );
                case 'FontWeight':
                  return (
                    <div>{ FontWeightEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <Select
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      >
                        <MenuItem value="normal">Normal</MenuItem>
                        <MenuItem value="bold">Bold</MenuItem>
                      </Select>
                      </Box>

                      )}
                    </div>

                    
                  );
                case 'ValueType':
                  return (
                    <div>{ ValueTypeEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <div>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row // This will align the radio buttons in a single horizontal line
                            value={value} // This should be the state value
                            onChange={handleValueChange(key)} // Update state on change
                          >
                            <FormControlLabel value="static" control={<Radio />} label="Static Value" />
                            <FormControlLabel value="dynamic" control={<Radio />} label="Dynamic" />
                            <FormControlLabel value="conditional" control={<Radio />} label="Conditional" />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      </Box>

                      )}
                    </div>
                    
                  );
                case 'Tooltip':
                  return (
                    <div>{ TooltipEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!value}
                            onChange={handleValueChange(key)}
                          />
                        }
                        label={key}
                      />
                    </Box>

                    )}
                    </div>
                    
                  );
                case 'Redirection':
                  return (
                    <div>{ RedirectionEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                    <Typography variant="body1">{key}</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!value}
                          onChange={handleValueChange(key)}
                        />
                      }
                      label={key}
                    />
                    </Box>

                    )}
                    </div>
                    
                  );
                case 'TooltipText':
                  return (
                    <div>

                      {(TooltipTextEnabled && tableListData.WidgetProperties.Tooltip) && (
                        <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                          <Typography variant="body1">{key}</Typography>
                          <TextField
                            value={value}
                            onChange={handleValueChange(key)}
                            fullWidth
                            margin="dense"
                          />
                        </Box>
      
                      )}
                    </div>

                  );  
                case 'RedirectionLink':
                  return (
                    <div>

                      {(RedirectionLinkEnabled && tableListData.WidgetProperties.Redirection) && (
                        <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                          <Typography variant="body1">{key}</Typography>
                          <TextField
                            value={value}
                            onChange={handleValueChange(key)}
                            fullWidth
                            margin="dense"
                          />
                        </Box>
      
                      )}
                    </div>

                  );
                case 'Height':
                  return (
                    <div>{ HeightEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'Width':
                  return (
                    <div>{ WidthEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'Xpos':
                  return (
                    <div>{ XposEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'Ypos':
                  return (
                    <div>{ YposEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'MarginLeft':
                  return (
                    <div>{ MLEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'MarginRight':
                  return (
                    <div>{ MREnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'MarginTop':
                  return (
                    <div>{ MTEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'MarginBottom':
                  return (
                    <div>{ MBEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'Padding':
                  return (
                    <div>{ PaddingEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'FontSize':
                  return (
                    <div>{ FontSizeEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'Border':
                  return (
                    <div>{ BorderEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'BlockSize':
                  return (
                    <div>{ BlockSizeEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> {key} </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'Zindex':
                  return (
                    <div>{ ZindexEnabled && (
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1"> Stacking Order (z-index) </Typography>
                      <TextField
                        type="number"
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div> 
                  );
                case 'BackgroundColor':
                  return (
                    <div>{ BgColorEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <TextField
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div>
                    
                  );
                case 'LabelColor':
                  return (
                    <div>{ LabelColorEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <TextField
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div>
                    
                  );
                case 'OverflowWrap':
                  return (
                    <div>{ OverflowWrapEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <TextField
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div>
                    
                  );
                case 'TextAlign':
                  return (
                    <div>{ TextAlignEnabled &&(
                      <Box sx={{ border: '1px solid #ccc', padding: '16px', borderRadius: '4px', backgroundColor: '#e5f3ff',}}>
                      <Typography variant="body1">{key}</Typography>
                      <TextField
                        value={value}
                        onChange={handleValueChange(key)}
                        fullWidth
                        margin="dense"
                      />
                      </Box>

                      )}
                    </div>
                    
                  );
                default:
                  return null;
              }
            };

            return (
              <div key={key}>
                {/* <Typography variant="body1">{key}</Typography> */}
                {renderComponent()}
              </div>
            );
          })}
        </DialogContent>


        <DialogActions>
          <SaveChangesDialog
          projectName = {projectName}
          dashboardType = {dashboardType}
          widgetName = {widgetName}
          id = {params.row._id}
          rowData = {tableListData}
          singleImage = {targetImage}
          imageName = {tableListData.WidgetProperties.WidgetDescription}

          email = {user.email}
          role = {user.role}
          tagName = {tableListData.WidgetName}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>

      {!!snackbar && (
          <Snackbar anchorOrigin={{ vertical:"top", horizontal:"center" }}  open onClose={handleCloseSnackbar} autoHideDuration={4000}>
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
      )}

    </div>
  );
}