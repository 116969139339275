import * as React from 'react';
import { useState, useEffect, useCallback } from "react";
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import { TextField, Button, FormControl, makeStyles } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import backgroundImage from "../../assets/Welcome.gif";
import {uploadImageToServer} from "../../slices/auth";
import { clearMessage } from "../../slices/message";

import DashboardViewService from '../../services/dashboardview.service.js';
import AuditTrailViewService from '../../services/audittrailview.service.js';
import ConfigurationViewService from '../../services/configurationview.service.js';
import UserManagementService from '../../services/usermanagement.service.js';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

// Check blank values inside the row data
function hasBlankOrNullValues(obj) {
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === '') {
      return true; // Found a blank or null value
    }

    if (typeof value === 'object' && hasBlankOrNullValues(value)) {
      return true; // Recursively check nested objects
    }
  }

  return false; // No blank or null values found
}

//Convert Image to File
const convertImageToFile = async (imageSrc, imageName) => {
  const response = await fetch(imageSrc);
  const blob = await response.blob();
  return new File([blob], imageName, { type: 'image/jpeg' });
};

//Convert svg to File
const convertSvgToFile = async (svgSrc) => {
  const response = await fetch(svgSrc);
  const blob = await response.blob();
  return new File([blob], 'image.svg', { type: 'image/svg+xml' }); // Specify the MIME type for SVG
};

// Function to create auditTrailData
const createAuditTrailData = (oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus) => {
  
  let auditTrailData = [];
  // Get the keys of the oldValues
  const keys1 = Object.keys(oldValues);
  //console.log(keys1)
  //console.log(Object.keys(newValues))
  //console.log(keys1.length, Object.keys(newValues).length)
  // Check if the number of keys is the same
  if (keys1.length !== Object.keys(newValues).length) {
    return auditTrailData;
  }else{
    // Iterate over the keys of the oldValues
    for (let key of keys1) {
      //console.log(key, oldValues[key], newValues[key]);
      
      let activityStatus = activity;
      // modify Activity if event is enabled else activity will be deafualt value received form audittrail config collection
      if(eventStatus === "Enabled"){
        if(key === "dashboardgroupnamelist"){
          if(newValues[key].length === 0){
            activityStatus = `Within dashboardlist, created new dashbaord - ${tagName} and no user group assigned to it`;
            auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }
          if(newValues[key].length > 0){
            activityStatus = `Within dashbaordlist, created new dashbaord - ${tagName} and assigned user groups are  '${newValues[key]}'`;
            auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
          }
          
        }
        
      } else{
        auditTrailData.push({TimeStamp: new Date(), Activity: activityStatus, ActivityType: activityType, UserName: userName, Role: role, Remark: remark, EventStatus: eventStatus});
      }  
      
    }
    return auditTrailData;
  }

  

};

function SaveChangesDialog(props) {
  const [open, setOpen] = useState(false);
//MUI Component Referances ==> MessageBox=SnackBar, PopupWindow=Paper or dialogueBox, DropdownList=Select, Image= image, TextInput=textField, textDispaly=Typography, 
  //const widgetConfigData = [{
    //"ProjectName": props.projectName ,
    //"DashboardType": props.dashboardType,
    //"DashboardName": props.widgetName,
    //"WidgetList": ['BackgroundImage', 'Button', 'CheckBox', 'DrpdownList', 'Image', 'Messagebox', 'NumericInput', 'NumericDisplay', 'PopupWindow', 'Slider', 'Switch', 'Table', 'TextInput', 'TextDisplay', 'Tooltip'],
    //"WidgetProperties":{'BackGroundImage':{'ImageName': props.widgetName + "_BackgroundImage", "Enable": "Enabled"}, },
  //}];

  const widgetConfigData = [{ 
    "ProjectName": props.projectName ,
    "DashboardType": props.dashboardType,
    "DashboardName": props.widgetName,
    "WidgetName": 'BackgroundImage',
    "WidgetProperties":{  'WidgetDescription': props.widgetName + "_BackgroundImage", 
                          'Visibility': 'true', 'ImageName': props.widgetName + "_BackgroundImage", 'ValueType':'static', 'Height':'100', 'Width':'100', 
                          'Xpos':'0', 'Ypos':'0', 'MarginLeft':'0', 'MarginRight':'0', 'MarginTop':'0', 'MarginBottom':'0', 'Padding':'0', 
                          'Background':'transparent', 'BackgroundColor':'white', 'LabelColor':'black', 'FontWeight':'normal', 'FontSize':'12', 'Border':'0', 
                          'OverflowWrap':'break-word', 'BlockSize':'50', 'Redirection':'false', 'RedirectionLink':'','TextAlign':'Center', 
                          'Tooltip':'false', 'TooltipText':'', 'Zindex':'1',
                        },

  }]

  const [auditTrailData, setAuditTrailData] = React.useState([]);  // AuditTrail Data

  const [openRemarkBlnkMsg, setOpenRemarkBlnkMsg] = useState(false);
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {dispatch(clearMessage());}, [dispatch]);

  const handleClickOpen = () => {

    if(((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === "--")) || ((props.rowData.RemarkStatus === "Enabled") && (props.auditTrailRemark === ""))){
      setOpenRemarkBlnkMsg(true);
      //console.log("Enter or change the Remark value to proceed further")
    }else{
    setOpen(true);
    }
    
  };

  const handleClose = () => {
    setOpen(false);
    setOpenRemarkBlnkMsg(false);
  };

  const handleSuccessClose = () => {
    setOpenSuccessDialog(false);
    props.success(true);
  };

  const handleFildClose = () => {
    setOpenFaildialog(false);
  };
  

  const [message, setMessage] = useState();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFaildialog, setOpenFaildialog] = useState(false);

  const [saveData, setSaveData] = useState(false);
  const handleClickSaveChanges = () => { 
    let oldValues = props.oldValues;
    let newValues = props.newValues;
    let tagName = props.tagName;
    let activity = props.activity;
    let activityType = 'Add Dashboard';
    let userName = props.email;
    let role = props.role;
    let remark = props.auditTrailRemark;
    let eventStatus = props.rowData.EventStatus;

    let auditTrailData = createAuditTrailData(oldValues, newValues, tagName, activity, activityType, userName, role, remark, eventStatus )
    //console.log(auditTrailData)
    setAuditTrailData(auditTrailData)
    setSaveData(true);
  };

  //Get Application Configuration data 
  useEffect(() =>{
    async function getColumnData()  {
      try {

        let dashboardName = "ApplicationConfig";
        const response = await ConfigurationViewService.getAllRowsData(dashboardName);

        if(response.data.length > 0){
          //setPwdInterlock(response.data[7].TaskStatus);
          //console.log(response.data[7].TaskStatus); 
        }

        
        
      } catch (error) {
        console.log(error);

      }
    }
    getColumnData();



  }, []);

  const [widgetList, setWidgetList] = React.useState([]);
  // Get DashboardName list
  useEffect(() =>{
    async function getAllColumnData()  {
    try {
      let dashboardName = "DashboardList"
      let columnName = "DashboardType"
      let columnValue = "DashboardView";

      const response = await DashboardViewService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
        let DashboardNames = response.data.map((item) => item.DashboardName);
        setWidgetList([...new Set(DashboardNames)]); 
      }

    } catch (error) {
      console.log(error);

    }
  };
    getAllColumnData();

  }, []);


  //  Add new row to WidgetList
  useEffect(() =>{
    async function saveRowData()  {
    try {

      let dashboardName = "DashboardList"
      let rowData = {...props.rowData, PWDInterlock: props.rowData.PWDInterlock, PWD: password}; // It should include all three field ie ProjectName, DashboardType, DashboardName
      //console.log(rowData)
      // Tag name & DataField Duplcation check for new tag
      if (widgetList.includes(rowData.DashboardName)) {
        setOpenFaildialog(true);
        setMessage("Widget Name already exist. Choose different Widget to avoid duplication.");
        setSaveData(false);
        setOpen(false); // Close "Save changes" window

      }else{
        // Blank value check for the  one of the form field
        if (rowData.DashboardName === '') {
          setOpenFaildialog(true);
          setMessage("Widget Name field is blank, please correct it.");
          setSaveData(false);
          setOpen(false); // Close "Save changes" window

        } else {
            const response = await DashboardViewService.createRow(dashboardName, rowData, auditTrailData);  // create new record if id is not present in array data ie existing data
            if(response){
              // Add widget config Data for newly created widget
              let dashboardName= "DashboardConfig"
              //console.log(widgetConfigData)
              const response1 = await DashboardViewService.insertMultipleRow(dashboardName, widgetConfigData);  // Insert multiple records
              if(response1){
                let imageName = props.widgetName + "_BackgroundImage"
                const singleImage = await convertImageToFile(backgroundImage, imageName);
                dispatch(uploadImageToServer({ imageName, singleImage }))
                .unwrap()
                .then(() =>{
                  //console.log(imageName)
                  setOpenSuccessDialog(true);
                  setMessage("Record Added Successfully, Reload the application to get new widget name list.");
                  setSaveData(false);
                  setOpen(false); // Close "Save changes" window
          
                })
                .catch(() => {
                  setOpenFaildialog(true);
                  setSaveData(false);
                  setOpen(false); // Close "Save changes" window
                  setMessage("Failed to save image");
                  //console.log("Failed to save image");
                });

              }
              
      
            }
        }
      }

    } catch (error) {
      setOpenFaildialog(true);
      setMessage("Error While Adding Record, Please very all parameters entered in form");
      setOpen(false); // Close "Save changes" window
      console.log(error);


    }
  };
  if(saveData){
    saveRowData();
    setSaveData(false);
  };


  }, [saveData]);

 
  

  return (
    
    <div>
      <Button variant="outlined" startIcon ={<SaveIcon/>} sx={{mt:1}} onClick={handleClickOpen}>SAVE CHANGES </Button>

      {/* Open Remark Field is blank dialog box */}
      <Dialog open={openRemarkBlnkMsg} onClose={handleClose}>
        <DialogTitle>Enter Remark Field</DialogTitle>
        <Box sx={{width: 450 }}>
        <DialogContent dividers>
          <DialogContentText>
              Enter or change the Remark Field to proceed further.
              <br />
              (Note: '--' Value for remark field is not acceptable)
          </DialogContentText>
          
        </DialogContent>
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Open Dialog to ask surity to edit user's data, with or withoout password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>SAVE CHANGES</DialogTitle>
        <Box sx={{width: 400 }}>

        <DialogContent dividers>
          <DialogContentText>
            Are you sure to add new widget.
          </DialogContentText>
          
        </DialogContent>

        {props.rowData.PWDInterlock === 'Enabled' && (<DialogContent dividers>
          <DialogContentText>
            Enter the Password to add new table.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />

          </DialogContent>
        )}


        </Box>
        <DialogActions>
          <Button onClick={handleClickSaveChanges}>Save</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Success dialog after upgradtion of teh user info. */}
      <Dialog
        open={openSuccessDialog}
        onClose={handleSuccessClose}
       
      >
        <DialogTitle > success.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Save Changes Failed dialog /Wrong Password */}
      <Dialog
        open={openFaildialog}
        onClose={handleFildClose}
       
      >
        <DialogTitle > Failed.. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            { message } 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFildClose}>Close</Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function WidgetListAdd ({open, close}) {
  const { user } = useSelector((state) => state.auth);
  const projectName = user.selectedproject;
  const dashboardType = 'DashboardView';
  const [widgetName, setWidgetname] = useState("")
  //console.log(ProjectName, DashboardType);


  const initialConfigData = {
    //id: null,
    ProjectName: projectName ,
    DashboardType: dashboardType,
    DashboardName: "",
    DashboardDescription:"",
    dashboardgroupnamelist:"",
    groupnamelist:"Common",
    
    
    email: user.email,
    role: user.role,  
    RemarkStatus : "Disabled",
    EventStatus:"Disabled",
    PWDInterlock:"Disabled",          
  };

  const [eventDescription, setEventDescription] = useState("--");
  const [auditTrailRemark, setAuditTrailRemark] = useState("--");
  // parameter list for audit trail log
  const oldValues = {DashboardName: "", DashboardDescription:"", dashboardgroupnamelist:"", groupnamelist:"Common",};

  const [newValues, setNewValues] = useState(oldValues);

  const [tableListData, setTableListData] = React.useState(initialConfigData);
  const [groupNameList, setGroupNameList] = React.useState([]);
  const [dashboardGroupNameList, setDashboardGroupNameList] = React.useState([]);
  const [selecetdGroupNameList, setSelecetdGroupNameList] = React.useState(["Common"]);
  const [selecetdDashboardGroupNameList, setSelecetdDashboardGroupNameList] = React.useState([]);
  

  // Get USer GroupName list
  useEffect(() =>{
    async function getAllColumnData()  {
      try {
      let dashboardName = "UserGroupList"
      let columnName = "DashboardType"
      let columnValue = "UserManagement";

      const response = await UserManagementService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
          let GroupNames = response.data.map((item) => item.GroupName);
          setGroupNameList([...new Set(GroupNames)]); 
      }

      } catch (error) {
      console.log(error);

      }
    };
    getAllColumnData();

  }, []);

  // Get Dashboard GroupName list
  useEffect(() =>{
    async function getAllColumnData()  {
      try {
      let dashboardName = "DashboardGroupList"
      let columnName = "DashboardType"
      let columnValue = "DashboardView";

      const response = await DashboardViewService.getColumnData(dashboardName, columnName, columnValue);
      if (response){
          let GroupNames = response.data.map((item) => item.GroupName);
          setDashboardGroupNameList([...new Set(GroupNames)]); 
      }

      } catch (error) {
      console.log(error);

      }
    };
    getAllColumnData();

  }, []);

  // Get AuditTrail Configuration data for Remark field, either it is enabled or disabled
  useEffect(() =>{
    async function auditTrailConfigData()  {
    try {

      let dashboardName = 'AuditTrailConfig';
      let columnName = 'EventName';
      let columnValue = 'Add Dashboard';
      let response = await AuditTrailViewService.getColumnData(dashboardName, columnName, columnValue);

      //console.log(response.data[0].EnableRemark);
      if(response.data.length > 0){
        setTableListData({...tableListData, EventStatus: response.data[0].EnableEvent, PWDInterlock: response.data[0].EnablePwdInterlock, RemarkStatus: response.data[0].EnableRemark});

        setEventDescription(response.data[0].DefaultEventDescription);
        // If remark field is disabled then set default remark vlaue to currrent Remark value
        if(response.data[0].EnableRemark === "Disabled"){
          setAuditTrailRemark(response.data[0].DefaultEventRemark);
        }
        
      }
  
    } catch (error) {
      console.log(error);

    }
  }

  auditTrailConfigData();

  }, []);

 
  const handleClose = () => {
    //console.log("Close Buton Pressed");
    close() // Close the alarm add main window
  };

  const handleRecordSaveSuccess = (data) =>{
    close(data)
  }


  const handleTextFieldChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});

    setWidgetname(event.target.value);
  };

  const handleWidgetDescrChange = (event) => {

    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});
  };

  const handlemultipleSelect = (event) => {
    setSelecetdGroupNameList(event.target.value); 
    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});
  };

  const handlemultipleDashboardGroupSelect = (event) => {
    setSelecetdDashboardGroupNameList(event.target.value); 
    const {name, value} = event.target;
    setTableListData({...tableListData, [name]:value});
    setNewValues ({...newValues, [name] :value});
  };

  const handleRemarkChange = (event) => {
    const {name, value} = event.target;
    if(tableListData.RemarkStatus === "Enabled"){
      setAuditTrailRemark(value);

    }
  };


  return (
    <div>
      
      <BootstrapDialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            maxWidth: '1000px', // Change this value as needed
          },
        }}
      >
        {/* Hnadle multiple functions on single click ie edit switch set to false and close the MyAccount Dialog */}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={() => {handleClose();  }}>
          Add New Dashboard to the List.
        </BootstrapDialogTitle>

        {/*Widget Name setting */}
        <DialogContent dividers>
          

          {/* ProjectName */}
          <TextField
            autoFocus
            margin="dense"
            label="ProjectName"
            type="text"
            fullWidth
            variant="standard"
            name="ProjectName"
            value={projectName}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* DashboardType */}
          <TextField
            autoFocus
            margin="dense"
            label="DashboardType"
            type="text"
            fullWidth
            variant="standard"
            name="DashboardType"
            value={dashboardType}
            onChange={handleTextFieldChange}
            disabled={true}
          />

          {/* DashboardName */}
          <TextField
            autoFocus
            margin="dense"
            label= "Dashboard Name"
            type="text"
            fullWidth
            variant="standard"
            name="DashboardName"
            value={tableListData.DashboardName}
            onChange={handleTextFieldChange}
            disabled={false}
          />

          {/* Dashboard Description */}
          <TextField
            margin="dense"
            label="Dashboard Description"
            type="text"
            fullWidth
            variant="standard"
            name="DashboardDescription"
            value={tableListData.DashboardDescription}
            onChange={handleWidgetDescrChange}
            disabled={false}
          />
          

          {/* Dashboard Group name */}
          <FormControl sx={{ m: 0, width: 200, ml:2, mt: 2 }} variant="standard">
              <InputLabel id="demo-customized-select-label">Dashboard Group Name</InputLabel> 
              <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              autoFocus
              multiple
              name="dashboardgroupnamelist"
              value={selecetdDashboardGroupNameList}
              disabled={false}
              onChange={handlemultipleDashboardGroupSelect}
              //input={<BootstrapInput />}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              
              >
              {dashboardGroupNameList.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    //disabled={name === 'Common'}
                  >
                    <Checkbox checked={selecetdDashboardGroupNameList.indexOf(name) > -1 }/>
                    <ListItemText primary={name} />
                    
                  </MenuItem>
                ))}
              </Select>
          </FormControl>

          {/* User Group name */}
          <FormControl sx={{ m: 0, width: 200, ml:2, mt: 2 }} variant="standard">
              <InputLabel id="demo-customized-select-label">User Group Names</InputLabel> 
              <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              autoFocus
              multiple
              name="groupnamelist"
              value={selecetdGroupNameList}
              disabled={false}
              onChange={handlemultipleSelect}
              //input={<BootstrapInput />}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              
              >
              {groupNameList.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    //disabled={name === 'Common'}
                  >
                    <Checkbox checked={selecetdGroupNameList.indexOf(name) > -1 }/>
                    <ListItemText primary={name} />
                    
                  </MenuItem>
                ))}
              </Select>
          </FormControl>


        </DialogContent>

        {/*Remark */}
        {tableListData.RemarkStatus === 'Enabled' && (<DialogContent 
        dividers
        >
          <Typography gutterBottom sx={{ mt: 0, mb:3, width: 400 }}>
          Enter remark/reason 
          </Typography>

          <TextField
            id="outlined-multiline-static"
            label="Remark"
            name="Remark"
            placeholder="Enter Remark"
            multiline
            rows={2}
            style={{ width: 550 }}
            onChange={handleRemarkChange}
            //defaultValue="Default Value"
          />

        </DialogContent>
        )}

        <DialogActions>
          <SaveChangesDialog
          projectName = {projectName}
          dashboardType = {dashboardType}
          widgetName = {widgetName}
          rowData = {tableListData}

          email = {user.email}
          role = {user.role}
          oldValues = {oldValues}
          newValues = {newValues}
          activity = {eventDescription}
          tagName = {tableListData.DashboardName}
          auditTrailRemark = {auditTrailRemark}

          success = {handleRecordSaveSuccess}
          />
        </DialogActions>
        
      </BootstrapDialog>
    </div>
  );
}



