import axios from "axios";
import {API} from './api.js';
const API_URL = API + '/dashboardview/'


//Get count of records present in Dashboardview
const getRowsCount = (dashboardName) => {

  return axios.post(API_URL + "count", {
    dashboardName,
    })
    
};


// get specific rows data, init record = page * rowsPerPage and number of record to fetch = rowsPerPage
const getRowsData = (dashboardName, offset, rowsPerPage) => {

  return axios.post(API_URL + "data", {
      dashboardName,
      offset,
      rowsPerPage
    })
};

// Get all rows data present inside the Dashboardview
const getAllRowsData = (dashboardName) => {
  return axios.post(API_URL + "all", {
    dashboardName,
  });
 
};

// Get all rows data present inside the Dashboardview
const getColumnData = (dashboardName, columnName, columnValue) => {
  return axios.post(API_URL + "columnData", {
    dashboardName,
    columnName,
    columnValue,
  });
 
};

// Get all field names of the specific Dashboardview/collection
const getSchemaKeys = (dashboardName) => {
  return axios.post(API_URL + "schemaKeys", {
    dashboardName,

  });
 
};


// Save specific row data present inside the Dashboardview
const updateRowData = (dashboardName, rowData, auditTrailData) => {

  return axios.post(API_URL + "update", {
    dashboardName,
    rowData,
    auditTrailData,
  });
   
};


// Create or Add document/record to existing collection/Dashboardview
const createRow= (dashboardName, rowData, auditTrailData) => {
    return axios.post(API_URL + "create", {
      dashboardName,
      rowData,
      auditTrailData,
      })
};

//get data from Dashboardview/document(dashboardName), retrieve specific column's(columnsToRetrieve) data based on specific values(valueToMatch ) of one of the column(columnToFilter)
const getMultiColumnsRows=(dashboardName, columnsToRetrieve, columnToFilter, valueToMatch ) => {
  return axios.post(API_URL + "multiColumnsRows", {
    dashboardName, 
    columnsToRetrieve,
    columnToFilter,
    valueToMatch
  })

};

// insert multiple document/record to existing collection/Dashboardview
const insertMultipleRow=(dashboardName, widgetConfigData ) => {
  return axios.post(API_URL + "multiInsert", {
    dashboardName, 
    widgetConfigData
  })

};

// delete multiple document/record of existing collection/Dashboardview for specific condition ie delete data if values matches for given fields.
const deleteMultipleRows=(dashboardName, rowData ) => {
  return axios.post(API_URL + "deleteMany", {
    dashboardName, 
    rowData, 
  })

};

// Delete specific document/record from existing collection/Dashboardview
const deleteRow = (dashboardName, rowData, auditTrailData) => {
    return axios.post(API_URL + "delete", {
      dashboardName,
      rowData,
      auditTrailData,
      })
  };

// Create the Tag at Writeconfig collection
const createTag= (dashboardName, rowData) => {
  return axios.post(API_URL + "createtag", {
    dashboardName,
    rowData,
    })
};

// Get Data fro provided Tag and from Writeconfig collection
const readTag = (dashboardName, columnName, columnValue) => {
  return axios.post(API_URL + "readtag", {
    dashboardName,
    columnName,
    columnValue,
  });
 
};

// Update the Tag to WriteConfig collection
const updateTag = (dashboardName, rowData) => {

  return axios.post(API_URL + "updatetag", {
    dashboardName,
    rowData,
  });
   
};

// delete the Tag from WriteConfig collection
const deleteTag = (dashboardName, rowData, auditTrailData) => {

  return axios.post(API_URL + "deletetag", {
    dashboardName,
    rowData,
    auditTrailData,
  });
   
};

//Send Image to Server
const uploadImageToServer = (imageName, singleImage) => {
  let singleImageFile = new FormData();
  singleImageFile.append("fileName", imageName);
  singleImageFile.append("file", singleImage);
  

  
  return axios.post(API_URL + "uploadImageToServer", 
  singleImageFile,
  { headers: {'Content-Type': 'multipart/form-data'}}
  
  )
};



//Receive image from Server
const downloadImageFromServer = (imageName) => {

  return axios.get(API_URL + `downloadImageFromServer/${imageName}`,
  {
    responseType: 'arraybuffer'
  }
  )
};

//Delete image from Server
const deleteImageFromServer = (imageName) => {
  return axios.delete(API_URL + `deleteImageFromServer/${imageName}`);
};



const TableViewService = {
  getRowsCount,
  getRowsData,
  getAllRowsData,
  updateRowData,
  getColumnData,
  createRow,
  insertMultipleRow,
  deleteRow,
  deleteMultipleRows,
  getMultiColumnsRows,
  createTag,
  readTag,
  updateTag,
  deleteTag,
  uploadImageToServer,
  downloadImageFromServer,
  deleteImageFromServer,

}

export default TableViewService;